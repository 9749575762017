import {ReactElement} from "react"
import {useRouteLoaderData} from "react-router-dom";
import {Replenishment as SteamReplenishment} from "./Services/Steam/Replenishment";
import {Payment as SteamPayment} from "./Services/Steam/Payment";
import {Genshin} from "./Services/Genshin";
import {IPosition} from "../interfaces/position.interface";

export function Position(): ReactElement | null {
  const item = useRouteLoaderData("service") as IPosition;

  switch (item.type.key) {
    case 'service':
      switch (item.service?.key) {
        case 'steam':
          switch (item.service?.method.key) {
            case 'replenishment':
              return <SteamReplenishment />;
            case 'payment':
              return <SteamPayment />;
          }
          break;
        case 'genshin':
          return <Genshin />
      }
      break;
  }

  return null;
}
