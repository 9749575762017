import React, {ReactElement} from "react";
import { NotFound } from "./components/System/NotFound";
import {Index as Store} from "../Store/Index";
import {Index as Orders} from "../Order/Index";
import {Application as ApplicationLayout} from "./components/Layout/Application";
import {Management as ManagementLayout} from "./components/Layout/Management";
import {Position} from "../Store/components/Position";
import {useAppDispatch} from "./hooks/store";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {loader as loaderPosition, Product} from "../Store/components/Product";
import {Payment} from "../Payment/Payment";
import {Authorize} from "../Auth/Authorize";
import {Logout} from "../Auth/Logout";
import {Access} from "../Auth/components/Authorize/Access";
import {Management} from "../Management/Management";
import {Orders as ManagementOrders} from "../Management/Order/Orders";
import {Positions as ManagementItems} from "../Management/Store/Positions";
import {Codes as ManagementCodes} from "../Management/Promotional/Codes";
import {Banners as ManagementBanners} from "../Management/Advertisement/Banners";
import {Accounts as ManagementAccounts} from "../Management/Account/Accounts";
import {Groups as ManagementGroups} from "../Management/Account/Groups";
import {loader as loaderPositionItems, Items} from "../Management/Store/components/Items";

export function Routes(): ReactElement {
  const dispatch = useAppDispatch();

  const router = createBrowserRouter([
    {
      id: "store",
      element: <Authorize><ApplicationLayout/></Authorize>,
      children: [
        {
          id: "directory",
          path: "/",
          element: <Store/>
        },
        {
          id: "catalog",
          children: [
            {
              id: "service",
              path: "/:type/:id",
              loader: loaderPosition(dispatch),
              element: <Position />,
              errorElement: <NotFound />,
            },
            {
              id: "product",
              path: "/product/:id",
              loader: loaderPosition(dispatch),
              element: <Product />,
              errorElement: <NotFound />,
            },
          ]
        },
        {
          path: "/orders",
          element: <Access><Orders /></Access>,
          errorElement: <NotFound />,
        },
        {
          path: "/payment/:service/:type",
          element: <Payment />
        },
        {
          path: "/logout",
          element: <Logout />
        },
      ],
    },
    {
      element: <Authorize><ApplicationLayout/></Authorize>,
      children: [
        {
          path: "*", element: <NotFound/>
        }
      ],
    },
    {
      id: "management",
      element: <Authorize><ManagementLayout/></Authorize>,
      children: [
        {
          path: "/management",
          element: <Access roles={'admin'}><Management/></Access>,
          children: [
            {
              id: "orders",
              path: "/management/orders",
              element: <ManagementOrders />,
            },
            {
              id: "positions",
              path: "/management/positions",
              element: <ManagementItems />,
            },
            {
              id: "position",
              path: "/management/position/:id/items",
              loader: loaderPositionItems(dispatch),
              element: <Items />,
              errorElement: <NotFound />,
            },
            {
              id: "promotional",
              path: "/management/codes",
              element: <ManagementCodes />
            },
            {
              id: "advertisement",
              path: "/management/banners",
              element: <ManagementBanners />
            },
            {
              id: "accounts",
              path: "/management/accounts",
              element: <ManagementAccounts />
            },
            {
              id: "groups",
              path: "/management/groups",
              element: <ManagementGroups />
            }
          ]
        }
      ],
    },
  ]);

  return <RouterProvider router={router} />
}