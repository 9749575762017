import React, {ReactElement} from "react"

import {
  Autocomplete, Chip, useTheme
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {Control, Controller, useFieldArray, useWatch} from "react-hook-form";
import Box from "@mui/material/Box";
import {TextField} from "../../../../App/components/Input/TextField";

interface Props {
  control: Control<any>
}

export function Blacklist(props: Props): ReactElement {
  const {control} = props;
  const theme = useTheme()

  const { fields, replace, remove } = useFieldArray({
    control,
    name: "blacklist"
  });

  const values: Array<string> = useWatch({
    control,
    name: `blacklist`
  })

  return (
    <Grid item>
      <Controller
        name="blacklist"
        control={control}
        render={({
          field: { value }, fieldState
        }) => (
          <React.Fragment>
            <Autocomplete
              multiple
              freeSolo
              getOptionLabel={(option: string) => option}
              isOptionEqualToValue={(option, value) => option === value}
              noOptionsText={"Нет данных"}
              options={[]}
              loading={false}
              value={value}
              onChange={(e, value) => {
                replace(value)
              }}
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  label="Черный список:"
                  error={!!fieldState.error}
                  {...params}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            {fields.length ? (
              <Box
                sx={{
                  padding: `${theme.spacing(1.5)} 0`,
                  display: "flex",
                  flexFlow: "row wrap",
                  gridGap: theme.spacing(1.5)
                }}
              >
                {fields.map((_, index) => {
                  return <Chip key={index} variant="outlined" label={values[index]} onDelete={() => remove(index)}/>
                })}
              </Box>
            ) : null}
          </React.Fragment>
        )}
      />
    </Grid>
  );
}